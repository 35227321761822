import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import { io } from 'socket.io-client';
import './App.css';

import Grid from './game/Grid';
import { Phases } from './game/types';
import GameInfo from './game/GameInfo';
import LogPanel from './game/panels/LogPanel';
import CardPage from './game/CardPage';
import BuildPage from './game/BuildPage';
import BuildPage2 from './pages/build-page/build-page';
import { getPiece } from './game/pieces/champions';

// import { useFusionAuth } from "@fusionauth/react-sdk";
import { LoginButton, LogoutButton, Profile } from './Auth0';
import { BACKEND_URL } from './constants';
import ChampionCardPage, {ChampionCard} from './game/pieces/utils/champion-card/ChampionCard';
import SelectDeckPage from './game/pages/champions/SelectDeckPage';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import AuthWrapper from './AuthWrapper';
import Draft from './draft/draft';
import PieceCard from './game/pieces/cards/piece-card/piece-card';
import GameOverScreen from './game/pages/game-over-screen/game-over-screen';
import FullscreenButton from './utils/full-screen-button';
import { startTitleFlash, stopTitleFlash } from './utils/titleUtils';


import solbrewLogo from './assets/SOLBREW.png';
import HomePage from './pages/home-page/home-page';
import UserBuildsPage from './pages/user-builds-page/user-builds-page';




const Game = ({ gameId, hardCodedPlayerId, watcher }) => {
  const [gameState, setGameState] = useState(null);
  const [currentAction, setCurrentAction] = useState(null);
  const [targets, setTargets] = useState([]);
  const socket = useRef(null);
  // const [inputValue, setInputValue] = useState('');
  const [actionHovered, setActionHovered] = useState(null);
  const logEndRef = useRef(null);
  const [hoveredTile, setHoveredTile] = useState(null);
  const [championId, setChampionId] = useState(null);
  const [makePublicGame, setMakePublicGame] = useState(false);
  const [auth, setAuth] = useState({ auth: { token: '' } });
  const [cookieSet, setCookieSet] = useState(false);
  const [resignConfirm, setResignConfirm] = useState(false);
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const username = user ? user['solbrew.io/username'] : null;
  
  useEffect(() => {
    const fetchToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        setAuth({ auth: { token } });
      } catch (error) {
        console.error('Error fetching the token', error);
      }
    };
    fetchToken();
  }, []);

  useEffect(() => {
    // Only start flashing if we have a gameState
    if (gameState) {
      const { turn_idx, players } = gameState.state;
      const canAct = (turn_idx === playerId && !players[1-playerId].targetted) || players[playerId].targetted;
      
      if (canAct) {
        startTitleFlash();
      } else {
        stopTitleFlash();
      }
    }
    
    // Cleanup on component unmount
    return () => {
      stopTitleFlash();
    };
  }, [gameState]); // Depend on gameState instead of canAct

  const scrollToBottom = () => {
    if (logEndRef.current) {
      logEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const resetVariables = () => {
    setActionHovered(null);
    setHoveredTile(null);
    setCurrentAction(null);
    setTargets([]);
  };

  const isActionUsable = (position, action) => {
    const [x, y] = position;
    const cell = grid[x][y];
    return canAct && action.can_use && cell.owner_id === playerId;
  };

  const onLegalTargets = () => {
    if (!currentAction.name) { return }
    console.log('legal targets for act', `${currentAction.name} ${currentAction.sourcePosition}`);
    if (currentAction && currentAction.name != "Move" && currentAction.legal_targets.length == 0){
      if (canAct && currentAction.can_use) {
        console.log('submitting', gameId, [`${currentAction.name} ${currentAction.sourcePosition}`]);
        socket.current.emit('submit', { gameId, username, actions: [`${currentAction.name} ${currentAction.sourcePosition}`], previous_targets: currentAction.previous_targets });
      }
      // resetVariables();
      // setCurrentAction(null);
    } else {
      setTargets(currentAction.legal_targets)
    }
  };

  useEffect(() => {
    console.log('gameId select click', gameId);
    if (gameId == null  || championId == null) {
      return;
    }
    console.log('joining', gameId, championId);
    socket.current.emit('join', { gameId, makePublicGame, 'characterId': championId, 'watcher': watcher });
  }, [championId, makePublicGame]);

  const setCookie = async (gameId) => {
    try {
      const response = await fetch(
        `${BACKEND_URL}/game/set_cookie?game_id=${gameId}`, { 
          method: 'GET',
          credentials: 'include', // Important for sending cookies
          headers: {
              'Content-Type': 'application/json',
          }
      });
  
      if (response.ok) {
        console.log('Cookie set successfully');
        setCookieSet(true);
      } else {
        console.error('Failed to set cookie:', response.statusText);
      }
    } catch (error) {
      console.error('Error setting cookie:', error);
    }
  };

  useEffect(() => {
    console.log('token', auth);
    if (!auth.auth.token) { return; }
    if (!cookieSet) {
      setCookie(gameId);
      return;
    }
    socket.current = io(`${BACKEND_URL}/game`, {...auth, withCredentials: true, transports: ['websocket']});

    socket.current.on('connect', () => {
      socket.current.emit('join', { gameId, makePublicGame, watcher });
      console.log('WebSocket connection opened');
      if (isNaN(hardCodedPlayerId)) {
        return;
      }
      console.log('joining', gameId, hardCodedPlayerId);
      socket.current.emit('join', { gameId, makePublicGame, playerId: hardCodedPlayerId, watcher });
    });

    socket.current.on('game_state', (data) => {
      console.log('WebSocket message received:', data);
      if (data.state) {
        setGameState(data);
        resetVariables();
      }
    });

    socket.current.on('error', (error) => {
      console.error('WebSocket error:', error);
      alert(error.error);
    });

    socket.current.on('legal_targets', (legal_targets) => {
      console.log('legal targets for act', currentAction, legal_targets);
      if (currentAction && currentAction.name != "Move" && legal_targets['legalTargets'].length == 0){
        resetVariables();
        setCurrentAction(null);
      } else {
        setTargets(legal_targets['legalTargets'])
      }
    });

    socket.current.on('more_info_needed', (data) => {
      console.log('more info needed', data);
      setCurrentAction({...data.action_config, sourcePosition: data.action_config.source_position, legal_targets: data.additional_targets});
    });

    return () => {
      socket.current.disconnect();
      console.log('WebSocket connection closed :(');
    };
  }, [gameId, cookieSet, auth]);

  useEffect(() => {
    scrollToBottom();
  });

  useEffect(() => {
      if (!currentAction) {
        return;
      }

      // console.log('getting legal targets', gameId, currentAction.name);
      // socket.current.emit('get_legal_targets', { gameId, playerId, sourcePosition:currentAction.sourcePosition, actionName: currentAction.name});
      // console.log('currentAction', currentAction);
      onLegalTargets();

      const handleKeyDown = (event) => {
        if (event.key === 'Escape') {
          resetVariables();
        }
      };
      document.addEventListener('keydown', handleKeyDown);
      
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
  }, [currentAction]);

  if (!auth.auth.token) {
    return <div>Loading...</div>;
  }

  if (!gameState) {
    return <SelectDeckPage championId={championId} setChampionId={setChampionId} setMakePublicGame={setMakePublicGame}  isGame={true} />;
  } 

  let { turn_idx, turn_phase, players, grid, log, player_id, winner } = gameState.state;
  const playerId = player_id;
  const canAct = (turn_idx === playerId && !players[1-playerId].targetted) || players[playerId].targetted;

  gameState.state.grid = grid.map(row => row.map(getPiece));

  const getCoolDown = (cell, action) => {
    if (!cell.cooldowns) { return 0; }
    return cell.cooldowns[action.name] || 0;
  };

  const renderActions = () => {
    if (turn_phase == Phases.ACTION) {
      if (!currentAction) {return}

      const [sx, sy] = currentAction.sourcePosition;
      const cell = grid[sx][sy];
      if (!cell.actions && !cell.default_action) {return}
      let cellActions = [];
      if (cell.piece_type == 'Champion' || cell.piece_type == 'Aide') {
        cellActions = cell.actions
      } else {
        cellActions = cell.default_action ? cell.actions ? [cell.default_action, ...cell.actions] : [cell.default_action] : cell.actions;
      }
      return cellActions.filter(action => action.type == "Action" || action.type == "Movement").map(action => {
        return (
          <div className={`action-button-container`}>
            <button key={action.name} className={`action-button ${!isActionUsable([sx, sy], action) ? 'cooldown' : ''}`}
            onMouseEnter={() => setActionHovered(action)}
            onMouseLeave={() => setActionHovered(null)}
            onClick={() => {
              if (isActionUsable([sx, sy], action)) {
                setCurrentAction((a) => { 
                  return {
                    ...action,
                    sourcePosition: [sx, sy],
                  }});
                }
              }}>
              {action.name}
            </button>
            {getCoolDown(cell, action) > 0 && <div className='cooldown-text'>{`Cooldown: ${getCoolDown(cell, action)}`}</div>}
          </div>
        );
      });
    } else if (turn_phase == Phases.REACTION && players[playerId].targetted) {
      const findChampionTile = () => {
        for (let i = 0; i < grid.length; i++) {
          for (let j = 0; j < grid[i].length; j++) {
            const tile = grid[i][j];
            if (tile.owner_id === playerId && tile.piece_type === 'Champion') {
              return [i, j];
            }
          }
        }
        return null;
      };

      const [sx, sy] = findChampionTile();
      const champion = grid[sx][sy];
      return champion.actions.filter(action => action.type == "Reaction").map(action => {
        return (
          <button key={action.name} 
            className={`action-button ${!isActionUsable([sx, sy], action) ? 'cooldown' : ''}`}
            onMouseEnter={() => setActionHovered(action)}
            onMouseLeave={() => setActionHovered(null)}
            onClick={() => {
              // setActionHovered(null);
              if (isActionUsable([sx, sy], action)) {
                setCurrentAction((a) => { return {
                  ...action,
                  sourcePosition: [sx, sy],
                }})}}}>{action.name}</button>
        );
      });
    }
  };

  return (
    <div className={`App ${canAct ? 'your-act' : 'not-your-act'}`}>
      <div className={`game-container ${canAct ? 'your-act' : 'not-your-act'}`}>
        <div className='game-info-container'>
          {actionHovered ? <PieceCard buildData={actionHovered} /> : <GameInfo gameState={gameState} canAct={canAct} playerId={playerId} />}
          <div className='game-info-container-buttons'>
            <button className="submitButton resign"
              onClick={() => {
                socket.current.emit('resign', { gameId });
              }}
            >
              Resign  
            </button>
            {(canAct && turn_phase != Phases.REACTION) && (
              <>
              <button className="submitButton end-turn" onClick={() => {
              if (turn_idx == playerId) {
                socket.current.emit('submit', { gameId, playerId, actions: [], end_turn: true });
                resetVariables();
              }
              }}>End Turn</button>
              </>
            )}
          </div>
          
        </div>
        <Grid
          playerId={playerId}
          gameId={gameId}
          gameState={gameState} setGameState={setGameState}
          currentAction={currentAction} setCurrentAction={setCurrentAction}
          targets={targets} setTargets={setTargets}
          socket={socket}
          flip={playerId === 0}
          canAct={canAct}
          resetVariables={resetVariables}
          hoveredTile={hoveredTile} setHoveredTile={setHoveredTile}
        />
        <div className='end-panel'>

          {!!hoveredTile ? (
            <PieceCard buildData={hoveredTile} />
          ) : 
            (
              <>
                <div className='log-panel-holder'>
                  <LogPanel log={log} action={currentAction} />
                </div>
                <div className='actions-panel'>
                  {renderActions()}
                </div>
              </>
            )}
              </div>

      </div>
      <div style={{'position': 'fixed', 'left': '10px', 'right': '10px'}}>
        <FullscreenButton />
      </div>
      <GameOverScreen win={winner === playerId} show={winner !== null} />
    </div>
  );
};

const App = () => {

  return (
    <Router>
      <AuthWrapper>
      <Routes>
        <Route path="/game/:gameId" element={<GameWrapper watcher={false} />} />
        <Route path="/game/watch/:gameId" element={<GameWrapper watcher={true} />} />
        <Route path="/draft/:draftId" element={<DraftWrapper />} />

        <Route path="/:gameId/:playerId" element={<GameWrapper />} />
        <Route path="/card/:card" element={<CardPage />} />
        <Route path="/build/:buildId" element={<BuildPage />} />
        <Route path="/build_maker" element={<BuildPage2 />} />
        <Route path='/builds' element={<UserBuildsPage />} />
        <Route path="/" element={<HomePage/>}/>
        {/* </Route>//{<CreateGame />} /> */}
        <Route path="/card_test/:buildId" element={<ChampionCardPage />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/home" element={<HomePage />} />
      </Routes>
      {/* <LogoutButton /> */}
      </AuthWrapper>
    </Router>
  );
};

const GameWrapper = ({ watcher }) => {
  // console.log('game wrapper watching:', watcher);
  const { gameId, playerId } = useParams();
  return <Game gameId={gameId} hardCodedPlayerId={parseInt(playerId)} watcher={watcher} />;
};

const DraftWrapper = () => {
  const { draftId } = useParams();
  return <Draft draftId={draftId} />;
};

const CreateGame = () => {
  const handleCreateGameClick = () => {
    const randomPath = `/game/${(Math.random() + 1).toString(36).substring(7)}`;
    window.location.href = randomPath;
  };

  const handleCreateDraftClick = () => {
    const randomPath = `/draft/${(Math.random() + 1).toString(36).substring(7)}`;
    window.location.href = randomPath;
  };

  return (
    <div className='create-game' style={{'backgroundImage': `url(${solbrewLogo})`}}>
      <button onClick={() => handleCreateGameClick()}>Create Game</button>
      <button onClick={() => handleCreateDraftClick()}>Create Draft</button>
    </div>
  );
};

export default App;
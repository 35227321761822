import { FaHeart } from "react-icons/fa";
import { GiFrozenOrb } from "react-icons/gi";
import { GiSwordSpade, GiShield } from "react-icons/gi";
import { FiPackage } from "react-icons/fi";

import championStamp from "../../../assets/CHAMPION_FRAME.png";
import stamp from "../../../assets/STAMP.png";

const FillImage = ({ cell }) => {
  const isSpecialPiece = ["Champion", "Aide"].includes(cell.piece_type);

  return (
    <img
      src={cell.image}
      alt={cell.name}
      className={`
        ${isSpecialPiece ? "translate-x-[-50%] rounded-full z-[1] w-[105%] h-[105%] hue-rotate-0" : "h-full w-full"}
        ${cell.piece_type === "Aide" ? "translate-x-[-50%] scale-90" : ""}
      `}
    />
  );
};

const PieceImage = ({ cell, stats, flip }) => {
  const isSpecialPiece = ["Champion", "Aide"].includes(cell.piece_type);
  const showStatuses =
    cell.stuck > 0 || cell.was_stuck || (cell.delay_counters && cell.delay_counters > 0) || cell.assassination_target;

  return (
    <div className='w-[82px] h-[82px] flex justify-center items-center text-white font-bold rounded-[50%] relative'>
      <div
        className={`absolute flex flex-col bg-black px-[5px] pt-[3px] pb-0 rounded-[20%] -top-[5px] -left-[5px] z-10 
        ${showStatuses ? "flex" : "hidden"}`}
      >
        {(cell.stuck > 0 || cell.was_stuck) && (
          <div className='z-[3] mx-auto'>
            <GiFrozenOrb color='#31ccbc' />
            {cell.stuck > 0 ? cell.stuck : ""}
          </div>
        )}
        {cell.delay_counters && cell.delay_counters > 0 && (
          <div className='z-[3] mx-auto'>
            <FiPackage color='#31ccbc' />
            {cell.delay_counters}
          </div>
        )}
        {cell.assassination_target && (
          <div className='z-[3] mx-auto'>
            <FiPackage color='#31ccbc' />
            {cell.delay_counters}
          </div>
        )}
      </div>

      {isSpecialPiece && (
        <img
          src={cell.piece_type === "Champion" ? championStamp : stamp}
          alt='championStamp'
          className={`
            ${
              cell.piece_type === "Champion"
                ? "translate-x-[50%] scale-[140%] object-full z-[2]"
                : "translate-x-[50%] w-[140%] h-[140%] object-cover z-[2] scale-90"
            }
            ${
              cell.owner_id === 0
                ? flip
                  ? "hue-rotate-180"
                  : "hue-rotate-0"
                : flip
                ? "hue-rotate-0"
                : "hue-rotate-180"
            }
          `}
        />
      )}

      <FillImage cell={cell} />

      {stats && (
        <div className='absolute -bottom-1 -left-1  flex flex-row justify-between items-center w-[110%] text-xs z-[2] bg-gray-500 border border-black'>
          {cell.piece_type && ["Aide", "Champion"].includes(cell.piece_type) && (
            <div className='flex pl-2 mt-0.5'>
              <GiSwordSpade />
              <p className='flex text-xs'>{cell.strength}</p>
            </div>
          )}
          {!!cell.defense && (
            <div className='flex mt-0.5'>
              <GiShield />
              <p className='flex text-xs'>{cell.defense}</p>
            </div>
          )}
          {!!cell.health && (
            <div className='flex pr-2 mt-0.5'>
              <FaHeart color='red' />
              <p className='flex text-xs'>{cell.health[0]}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PieceImage;

import { BACKEND_URL } from "../constants";

export async function getAllBuilds() {
  try {
    const url = `${BACKEND_URL}/get_all_builds`;
    const response = await fetch(url, {
      method: "GET",
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching action:", error);
    return { error: error.message };
  }
}

export async function getAllPublicGameIds() {
  try {
    const url = `${BACKEND_URL}/get_public_games`;
    const response = await fetch(url, {
      method: "GET",
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching action:", error);
    return { error: error.message };
  }
}

export async function getAllActions() {
  try {
    const url = `${BACKEND_URL}/get_all_actions`;
    const response = await fetch(url, {
      method: "GET",
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching action:", error);
    return { error: error.message };
  }
}

export async function getAllChampions() {
  try {
    const url = `${BACKEND_URL}/get_all_champions`;
    const response = await fetch(url, {
      method: "GET",
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching action:", error);
    return { error: error.message };
  }
}

export async function getUserBuilds(bearerToken) {
  const url = `${BACKEND_URL}/get_user_builds`;

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const builds = await response.json();
    return builds;
  } catch (error) {
    console.error("Error fetching user builds:", error);
    return null;
  }
}

export async function saveBuild(bearerToken, buildData) {
  const url = `${BACKEND_URL}/save_build`;
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(buildData),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error saving build:", error);
  }
  return;
}

export function getChampionNameById(id) {
  switch (id) {
    case 0:
      return "Suvira";
    case 1:
      return "Gillian";
    case 2:
      return "Pierre";
    case 3:
      return "Lyri";
    case 4:
      return "Brisborealis";
    case 5:
      return "Hawei";
    case 6:
      return "Reyamori";
    case 7:
      return "Enes";
    case 8:
      return "Sildin";
    case 9:
      return "Kaelric";
    default:
      return id;
  }
}

import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { BACKEND_URL } from './constants';


async function testPrivate(token) {
    try {
        const url = `${BACKEND_URL}/private`;
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                // 'Content-Type': 'application/json'  
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;

    } catch (error) {
        console.error('Error fetching action:', error);
        return { error: error.message };
    }
}

// const LoginButton = () => {
//   const { loginWithRedirect } = useAuth0();
//   return <button onClick={() => loginWithRedirect()}>Log In</button>;
// };

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      // Optional: Add parameters for customization
      authorizationParams: {
        scope: 'openid profile username', // Request profile and email scopes
        // Add other parameters if needed
      }
    });
  };

  return <button onClick={handleLogin}>Log In</button>;
};

const LogoutButton = () => {
  const { logout } = useAuth0();
  return <button onClick={() => logout({ returnTo: window.location.origin })}>Log Out</button>;
};

const Profile = () => {
  const { user, isAuthenticated, getIdTokenClaims, getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState(null);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await getAccessTokenSilently();
        // console.log('~test~', await testPrivate(token));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, [isAuthenticated]);

  console.log('user', user);
  return (
    <>
    <LoginButton />
    {isAuthenticated && (
      <div>
        <h2>{user['solbrew.io/username']}</h2>
        <p>{user.email}</p>
      </div>
    )}
    {!isAuthenticated && (<p>Womp Womp</p>)}
    <LogoutButton />
    </>
  );
};


export { LoginButton, LogoutButton, Profile };

 // Backend URL
export const BACKEND_URL = process.env.REACT_APP_DEV 
? 'http://127.0.0.1:5000' 
: 'https://api.solbrew.io';

// Game Constants
export const MAX_BUILD_SIZE = 8;

// Colors
export const COLORS = {
PRIMARY: '#2c3e50',
SECONDARY: '#ffe69c',
BACKGROUND: '#f0f0f0',
BORDER: '#ccc',
HIGHLIGHT: '#fbff1e',
ACTION_BLUE: 'rgb(63, 89, 255)',
BUTTON_HOVER: '#008f99'
};

// Common CSS Classes
export const CSS_CLASSES = {
SELECTED: 'selected',
NOT_SELECTED: 'not-selected',
CHAMPION_ACTION: 'champion-action',
BUILD_ACTION: 'build-action'
};

// Action Types
export const ACTION_TYPES = {
ACTION: 'Action',
MOVEMENT: 'Movement',
REACTION: 'Reaction'
};

// Game Phases
export const PHASES = {
ACTION: 'Action',
REACTION: 'Reaction',
DRAFT: 'Draft Done'
};
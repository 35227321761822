import "./SelectDeckPage.css";

import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";

import { getPiece } from "../../pieces/champions";
import PieceCard from "../../pieces/cards/piece-card/piece-card";
import { getAllBuilds, getUserBuilds, getChampionNameById } from "../../../utils/getters";

const SelectDeckPage = ({ championId, setChampionId, setMakePublicGame }) => {
  let { getAccessTokenSilently } = useAuth0();

  const [prebuilds, setPrebuilds] = useState([]);
  const [userBuilds, setUserBuilds] = useState([]);
  const [custom, setCustom] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await getAccessTokenSilently();

        getAllBuilds().then((data) => {
          setPrebuilds(data);
        });

        getUserBuilds(token).then((data) => {
          setUserBuilds(data);
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const preBuildClick = () => {
    setChampionId(null);
    setCustom(false);
  };

  const customBuildClick = () => {
    setChampionId(null);
    setCustom(true);
  };

  const copyClick = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
      alert("URL copied to clipboard!");
    } catch (err) {
      console.error("Failed to copy URL: ", err);
    }
  };

  return (
    <div>
      <div>
        <div className='build-buttons'>
          <Link to={".."}>
            <button>Return to menu</button>
          </Link>
          <button onClick={preBuildClick} className={custom ? "disabled-button" : ""}>
            Prebuilds
          </button>
          <button onClick={customBuildClick} className={!custom ? "disabled-button" : ""}>
            Custom builds
          </button>
          <button onClick={() => setMakePublicGame(true)} className='make-public-button'>
            Make Challenge Public
          </button>
          <div className='selected-champion-text'>Selected Champion: {getChampionNameById(championId)}</div>
          <button onClick={copyClick}>Copy Game Link to Clipboard</button>
        </div>
      </div>
      <div className={`champion-page ${custom ? "custom" : ""}`}>
        {custom
          ? userBuilds.map((build, bId) => (
              <div
                className={`champion-card ${championId === build.build_name ? "selected" : ""}`}
                onClick={() => {
                  console.log("Clicked on build", bId);
                  setChampionId(build.build_name);
                }}
                key={bId}
              >
                <div className='user-build' key={bId}>
                  {build.build_name}
                </div>
              </div>
            ))
          : prebuilds.map((build, bId) => (
              <div
                className={`champion-card ${championId === bId ? "selected" : ""}`}
                onClick={() => {
                  console.log("Clicked on build", bId);
                  setChampionId(bId);
                }}
                key={bId}
              >
                <PieceCard buildData={getPiece(build)} />
              </div>
            ))}
      </div>
    </div>
  );
};

export default SelectDeckPage;

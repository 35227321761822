import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../common-components/navbar/navbar";
import PieceCard from "../../game/pieces/cards/piece-card/piece-card";
import { getPiece } from "../../game/pieces/champions";
import { getAllBuilds, getAllPublicGameIds } from "../../utils/getters";
import { IoIosRefreshCircle } from "react-icons/io";

const HomePage = () => {
  const [prebuilds, setPrebuilds] = useState([]);
  const [publicGames, setPublicGames] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getAllBuilds().then((data) => {
      setPrebuilds(data);
    });

    getAllPublicGameIds().then((data) => {
      setPublicGames(data);
    });
  }, []);

  const handleGameDoubleClick = (gameId) => {
    navigate(`/game/${gameId}`);
  };

  return (
    <div>
      <link href='https://fonts.googleapis.com/css?family=Bubblegum Sans' rel='stylesheet'></link>
      <Navbar />

      <div className='ml-[250px] bg-[#ecf0f1] h-full'>
        <section className='mb-10 mt-0 text-center'>
          <h1 className="w-full text-4xl text-[#ffe69c] bg-[#2c3e50] font-['Bubblegum_Sans']">Welcome to Solbrew!</h1>
          <p className='text-lg text-[#7f8c8d] leading-relaxed p-5'>
            Solbrew is a game merging the exciting worlds, actions, and customization you have access to in games like
            DnD and MTG while also injecting chess like tactics.
            <br />
            At a high level the game is turn-based, PvP, and takes place on a 6x6 grid. Each player has a champion which
            consists of a race, class, a set of stats, abilities, and actions they may make. You win when you reduce
            your opponent's champion's health to 0.
          </p>
        </section>

        <section className='text-center'>
          <h2 className='text-3xl text-[#2980b9] mb-5'>Open Games</h2>
          <table className='mx-auto border-collapse w-64'>
            <thead>
              <tr>
                <th className='border border-black p-1 flex items-center justify-around text-center'>
                  <span>Game ID</span>
                  <button
                    className='cursor-pointer mt-0 h-8 text-center items-center'
                    onClick={() => {
                      getAllPublicGameIds().then((data) => {
                        setPublicGames(data);
                      });
                    }}
                  >
                    <span className='relative -top-[5px]'>
                      <IoIosRefreshCircle size={24} />
                    </span>
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              {publicGames.map((gameId) => (
                <tr key={gameId} onDoubleClick={() => handleGameDoubleClick(gameId)}>
                  <td className='border border-black p-2'>{gameId}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>

        <section className='mt-5'>
          <h2 className='text-3xl text-[#2980b9] mb-5 text-center'>Current Champions</h2>
          <div className='flex justify-center flex-wrap'>
            {prebuilds.map((build) => (
              <div key={build.id} className='m-2'>
                <PieceCard buildData={getPiece(build)} />
              </div>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
};

export default HomePage;

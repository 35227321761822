import { useEffect, useState } from "react";
import ActionCard from "./pieces/cards/ActionCard";
import { useParams } from "react-router-dom";
import "./BuildPage.css";
import BuildCard from "./pieces/cards/BuildCard";
import { getPiece } from "./pieces/champions";
import PieceImage from "./pieces/utils/PieceImage";
import PieceCard from "./pieces/cards/piece-card/piece-card";
import { BACKEND_URL } from '../constants';

// Function to call the get_action API
async function getBuild(buildId) {
    try {
        const url = `${BACKEND_URL}/get_build?build_id=${encodeURIComponent(buildId)}`;
        const response = await fetch(url, {
            method: 'GET',
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;

    } catch (error) {
        console.error('Error fetching action:', error);
        return { error: error.message };
    }
};

const BuildPage = () => {
    const { buildId } = useParams();
    const [buildData, setBuildData] = useState(null);

    useEffect(() => {
        if (!buildId) {
            return;
        }
        getBuild(buildId).then(data => {
            if (data.error) {
                console.error('Error:', data.error);
            } else {
                console.log('Success:', data);
                setBuildData(()=>data);
            }
        });
    }, []);

    return (
        <>
            {buildData && (
                <div className="build-page">
                    <div className="build-header">
                        <PieceImage cell={getPiece(buildData)} stats={false} />
                        <h1>{buildData.name}</h1>
                    </div>
                    {/* <div className="build-page-card"> */}
                        {/* <BuildCard build={buildData} /> */}
                    {/* </div> */}
                    <div className="build-page-card-body">
                        <div className="build-actions">
                        {buildData.actions.filter(action => !['Move', 'Rest', 'Hit', 'Block', 'Mana Shield', 'No Reaction'].includes(action.name)).map((action, index) => (
                            <div className="build-page-card" key={index}>
                                <PieceCard buildData={getPiece(action)} />
                            </div>
                        ))}
                        </div>
                    </div>
                </div>    
            )}
        </>
    );


};

export default BuildPage;

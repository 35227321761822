import React from "react";
import { FaHeart } from "react-icons/fa";
import { GiSwordSpade, GiBrain, GiShield, GiConcentrationOrb, GiLeatherBoot } from "react-icons/gi";
import { RiLightbulbFlashFill } from "react-icons/ri";

export const ResourceStats = ({ resources, style }) => {
  const renderStat = (icon, resourceType, actualType, color) => {
    const value = resources[resourceType];
    const actualValue = resources.cost_actual ? resources.cost_actual[resourceType] : null;
    return (
      <div className={`flex items-center flex-1 text-xs ${actualValue != null && actualValue != value ? "font-bold" : ""}`}>
        <span className="mr-1">{icon}</span>
        <span className="text-black">{value}</span>
        {actualValue != null && actualValue != value && (
          <span className="text-xs ml-1" style={{ color }}>{`(${actualValue})`}</span>
        )}
      </div>
    );
  };

  return (
    <div className="flex flex-row font-bold" style={style}>
      {renderStat(<FaHeart color="red" />, "health", "cost_actual.health", "red")}
      {renderStat(<GiConcentrationOrb color="blue" />, "mana", "cost_actual.mana", "blue")}
      {renderStat(<GiLeatherBoot color="brown" />, "stamina", "cost_actual.stamina", "brown")}
    </div>
  );
};

export const ChampionStat = ({ player }) => {
  return (
    <div className="flex flex-col">
      {(player.champion.health || player.champion.mana || player.champion.stamina) && (
        <div className="flex flex-row justify-between font-bold text-lg">
          {player.champion.health && (
            <div className="flex items-center flex-1 mr-8 text-sm">
              <FaHeart className="mr-1" color="red" />
              {`${player.champion.health[0]} / ${player.champion.health[1]}`}
            </div>
          )}
          {player.champion.mana && (
            <div className="flex items-center flex-1 mr-8 text-sm">
              <GiConcentrationOrb className="mr-1" color="blue" />
              {`${player.champion.mana[0]} / ${player.champion.mana[1]}`}
            </div>
          )}
          {player.champion.stamina && (
            <div className="flex items-center flex-1 text-sm">
              <GiLeatherBoot className="mr-1" color="brown" />
              {`${player.champion.stamina[0]} / ${player.champion.stamina[1]}`}
            </div>
          )}
        </div>
      )}
      {(player.champion.strength || player.champion.intelligence || player.champion.defense) && (
        <div className="flex flex-row justify-between font-bold text-lg">
          {player.champion.strength && (
            <div className="flex items-center flex-1 mr-8 text-sm">
              <GiSwordSpade className="mr-1" />
              {player.champion.strength}
            </div>
          )}
          {player.champion.intelligence && (
            <div className="flex items-center flex-1 mr-8 text-sm">
              <RiLightbulbFlashFill className="mr-1" />
              {player.champion.intelligence}
            </div>
          )}
          {player.champion.defense && (
            <div className="flex items-center flex-1 text-sm">
              <GiShield className="mr-1" />
              {player.champion.defense}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const PlayerStats = ({ players, playerId }) => {
  const _players = playerId == 1 ? players : players.slice().reverse();
  
  return (
    <div className="mt-2.5 flex flex-col">
      {_players.map((player, playerIndex) => (
        <div 
          key={player.id} 
          className={`p-2.5 border border-gray-200 rounded-lg mb-2.5 flex flex-col text-white ${
            playerIndex == 1 ? "bg-[#00a2ff]" : "bg-[#ff4d4d]"
          }`}
        >
          <h3>{player.name} - {Math.round(player.elo)}</h3>
          <div className="flex flex-col justify-between">
            <ChampionStat player={player} />
            <div className="flex flex-col">
              {player.champion.proficiencies &&
                Object.entries(player.champion.proficiencies).map(([prof, value]) => (
                  <span key={prof}>{`${prof}: ${value}`}</span>
                ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PlayerStats;
let originalTitle = document.title;
let interval = null;

export const startTitleFlash = () => {
  if (interval) return;
  
  originalTitle = document.title;
  interval = setInterval(() => {
    document.title = document.title === originalTitle 
      ? '🎮 Your Turn!' 
      : originalTitle;
    
    // Add flashing class to favicon
    const favicon = document.querySelector("link[rel*='icon']");
    if (favicon) {
      favicon.classList.add('favicon-flash');
    }
  }, 1000);
};

export const stopTitleFlash = () => {
  if (interval) {
    clearInterval(interval);
    interval = null;
    document.title = originalTitle;
    
    // Remove flashing class from favicon
    const favicon = document.querySelector("link[rel*='icon']");
    if (favicon) {
      favicon.classList.remove('favicon-flash');
    }
  }
};
import React from 'react';
import { getPiece } from "../../champions";
import { ChampionStat, ResourceStats } from "../../../PlayerStats";

export const PieceCardHeader = ({ buildData }) => {
  let resources = !!buildData.cost ? buildData.cost : buildData.cost_preview;
  if (buildData.cost_actual) {
    resources.cost_actual = buildData.cost_actual;
  }
  return (
    <div className="flex flex-row">
      <div className="flex items-center justify-between w-full mb-2">
        <p className="bg-white font-bold pl-2 pr-2 w-full rounded-lg">{buildData.name}</p>
        {(buildData.cost || buildData.cost_preview) && (
          <div className="pt-2 pr-2">
            <div className="whitespace-nowrap">
              <ResourceStats resources={!!buildData.cost ? buildData.cost : buildData.cost_preview} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const PieceCard = ({ buildData }) => {
  let default_action = null;
  if (buildData.default_action && buildData.default_action.type != "Movement") {
    default_action = buildData.default_action;
  }

  let piece = null;
  if (buildData.aide || buildData.plot) {
    piece = buildData.aide ? buildData.aide : buildData.plot;
    piece = getPiece(piece);
  } else {
    buildData = getPiece(buildData);
  }

  return (
    <div className="flex flex-col w-96 h-[640px] bg-gray-300 p-3 rounded-lg shadow-md border border-gray-300">
      <PieceCardHeader buildData={buildData} />

      <div className="relative w-full h-64 rounded-lg mb-2 bg-gray-200 overflow-hidden">
        {buildData.image && (
          <img 
            src={buildData.image} 
            alt={`${buildData.name}`} 
            className="w-full h-full object-cover"
          />
        )}
        {piece && piece.image && (
          <img 
            src={piece.image} 
            alt={`${piece.name}`} 
            className="w-full h-full object-cover"
          />
        )}
      </div>

      <div className="flex flex-col flex-1">
        <div className="flex flex-col gap-2">
          <div className="main-card-details">
            {buildData.race && (
              <div className="bg-gray-100 border border-gray-300 rounded-lg p-2 whitespace-pre-wrap text-xs font-bold">
                <p className="text-xs font-bold">Race: {buildData.race.name}</p>
                {buildData.race.affinities.length > 0 && (
                  <p className="text-xs font-bold">
                    <span className="text-purple-600">Affinities: </span>
                    {buildData.race.affinities.join(", ")}
                  </p>
                )}
                {buildData.race.skills.length > 0 && (
                  <p className="text-xs font-bold">
                    <span className="text-purple-600">Skills: </span>
                    {buildData.race.skills.join(", ")}
                  </p>
                )}
                {buildData.race.effects && buildData.race.effects.length > 0 && (
                  <div>
                    {buildData.race.effects.map((effect, index) => (
                      <p key={index} className="text-xs font-bold">
                        <span className="text-purple-600">Effect: </span>
                        {`${effect.text}`}
                      </p>
                    ))}
                  </div>
                )}
                {buildData.race.actions && buildData.race.actions.length > 0 && (
                  <p className="text-xs font-bold">
                    <span className="text-purple-600">Race Actions: </span>
                    {buildData.race.actions.map((action) => action.name).join(", ")}
                  </p>
                )}
              </div>
            )}

            {buildData.cls &&
              buildData.cls.length > 0 &&
              buildData.cls.map((cls_, index) => (
                <div key={index} className="bg-gray-100 border border-gray-300 rounded-lg p-2 whitespace-pre-wrap mt-1 text-xs font-bold">
                  <p className="text-xs font-bold">Class: {cls_.name}</p>
                  {cls_.affinities.length > 0 && (
                    <p className="text-xs font-bold">
                      <span className="text-purple-600">Affinities: </span>
                      {cls_.affinities.join(", ")}
                    </p>
                  )}
                  {cls_.skills.length > 0 && (
                    <p className="text-xs font-bold">
                      <span className="text-purple-600">Skills: </span>
                      {cls_.skills.join(", ")}
                    </p>
                  )}
                  {cls_.effects && cls_.effects.length > 0 && (
                    <div>
                      {cls_.effects.map((effect, index) => (
                        <p key={index} className="text-xs font-bold">
                          <span className="text-purple-600">Effect: </span>
                          {`${effect.text}`}
                        </p>
                      ))}
                    </div>
                  )}
                  {cls_.actions && cls_.actions.length > 0 && (
                    <p className="text-xs font-bold">
                      <span className="text-purple-600">Class Actions: </span>
                      {cls_.actions.map((action) => action.name).join(", ")}
                    </p>
                  )}
                </div>
              ))}

            {(buildData.text ||
              (buildData.affinities && buildData.affinities.length > 0) ||
              (buildData.skills && buildData.skills.length) ||
              (buildData.effects && buildData.effects.length > 0) ||
              (default_action && default_action.text)) && (
              <div className="bg-gray-100 border border-gray-300 rounded-lg p-2 mt-1 whitespace-pre-wrap text-xs font-bold">
                {buildData.text && <h6 className="m-0">{buildData.text}</h6>}
                {buildData.affinities && buildData.affinities.length > 0 && (
                  <h6 className="m-0">
                    <span className="text-purple-600">Affinities: </span>
                    {buildData.affinities.join(", ")}
                  </h6>
                )}
                {buildData.skills && buildData.skills.length > 0 && (
                  <h6 className="m-0">
                    <span className="text-purple-600">Skills: </span>
                    {buildData.skills.join(", ")}
                  </h6>
                )}
                {buildData.movement &&
                  ((buildData.piece_type == "Aide" && buildData.movement[1] != 1) ||
                    (buildData.piece_type == "Champion" && buildData.movement[1] != 3)) && (
                    <h6 className="m-0">Movement: {buildData.movement[1]}</h6>
                  )}
                {buildData.effects && buildData.effects.length > 0 && (
                  <div>
                    {buildData.effects.map((effect, index) => (
                      <h6 key={index} className="text-xs font-bold">
                        <span className="text-purple-600">Effect: </span>
                        {`${effect.text}`}
                      </h6>
                    ))}
                  </div>
                )}
                {default_action && !["Move", "No Action"].includes(default_action.name) && (
                  <h6 className="m-0">
                    <span className="text-purple-600">Action: </span>
                    {default_action.text}
                  </h6>
                )}
                {buildData.actions &&
                  buildData.actions.filter((x) => !["Move", "Hit"].includes(x.name)).length > 0 &&
                  buildData.piece_type == "Aide" && (
                    <h6 className="m-0">
                      <span className="text-purple-600">Additional Actions: </span>
                      {buildData.actions
                        .filter((x) => !["Move", "Hit"].includes(x.name))
                        .map((action) => action.name)
                        .join(", ")}
                    </h6>
                  )}
              </div>
            )}

            {(buildData.requirements && Object.keys(buildData.requirements).length > 0) ||
            (!buildData.piece_type && buildData.proficiencies && Object.keys(buildData.proficiencies).length) ? (
              <div className="bg-gray-100 border border-gray-300 rounded-lg p-2 whitespace-pre-wrap mt-2 text-xs font-bold">
                {buildData.requirements && Object.keys(buildData.requirements).length > 0 && <h6 className="m-0">Requirements:</h6>}
                {buildData.requirements &&
                  Object.entries(buildData.requirements).map(([requirement, num], index) => (
                    <h6 key={index} className="m-0">{`${requirement}: ${num}`}</h6>
                  ))}
                {!buildData.piece_type &&
                  buildData.proficiencies &&
                  Object.keys(buildData.proficiencies).length > 0 && <h6 className="m-0">Proficiencies:</h6>}
                {!buildData.piece_type &&
                  buildData.proficiencies &&
                  Object.entries(buildData.proficiencies).map(([proficiency, num], index) => (
                    <h6 key={index} className="m-0">{`${proficiency}: ${num}`}</h6>
                  ))}
              </div>
            ) : null}
          </div>

          {piece &&
            ((piece.effects && piece.effects.length > 0) ||
              (piece.default_action && !["Move", "No Action"].includes(piece.default_action.name)) ||
              (piece.movement && piece.movement[1] != 1) ||
              (piece.actions && piece.actions.length > 0)) && (
              <div className="bg-gray-400">
                <div className="bg-gray-100 border border-gray-300 rounded-lg p-2 whitespace-pre-wrap">
                  {piece.movement && piece.movement[1] != 1 && <p className="text-xs font-bold">Movement: {piece.movement[1]}</p>}
                  {piece.effects && piece.effects.length > 0 && (
                    <div>
                      {piece.effects.map((effect, index) => (
                        <p key={index} className="text-xs font-bold">
                          <span className="text-purple-600">Effect: </span>
                          {`${effect.text}`}
                        </p>
                      ))}
                    </div>
                  )}
                  {piece.default_action && !["Move", "No Action"].includes(piece.default_action.name) && (
                    <p className="text-xs font-bold">
                      <span className="text-purple-600">Action: </span>
                      {piece.default_action.text}
                    </p>
                  )}
                  {piece.actions && piece.actions.length > 0 && (
                    <p className="text-xs font-bold">
                      <span className="text-purple-600">Additional Actions: </span>
                      {piece.actions.map((action) => action.name).join(", ")}
                    </p>
                  )}
                </div>
              </div>
            )}
        </div>

        <div className="mt-auto">
          {buildData.piece_type && (
            <div className="flex flex-row justify-between w-auto rounded-lg p-2 whitespace-nowrap">
              <ChampionStat player={{ champion: buildData }} />
            </div>
          )}
          {piece && piece.piece_type && (
            <div className="flex flex-row justify-between w-auto rounded-lg p-2 whitespace-nowrap">
              <ChampionStat player={{ champion: piece }} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PieceCard;
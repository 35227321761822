import React, { useEffect, useState } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './build-actions-list.css';

const DraggableAction = ({ action, index, moveAction, onRemove, isChampionAction, onHover }) => {
    const [, drag] = useDrag(() => ({
        type: 'ACTION',
        item: { index },
    }), [index]);

    const [, drop] = useDrop(() => ({
        accept: 'ACTION',
        hover: (draggedItem) => {
            if (draggedItem.index !== index) {
                moveAction(draggedItem.index, index);
                draggedItem.index = index; // Update the dragged item's index after moving
            }
        },
    }), [index, moveAction]);

    if (!action) {
        return (
            <li className="action-item empty-slot">
                <h4 className="action-name">Empty Slot</h4>
            </li>
        );
    }

    return (
        <li
            ref={(node) => drag(drop(node))}
            className={`action-item ${isChampionAction ? 'champion-action' : 'build-action'}`}
            onMouseEnter={() => onHover(action)}
            onMouseLeave={() => onHover(null)}
        >
            <h4 className="action-name">{action.name || 'Unnamed Action'}</h4>
            <p className="action-affinities">Affinities: {action.affinities?.join(', ') || 'None'}</p>
            <p className="action-skills">Skills: {action.skills?.join(', ') || 'None'}</p>
            {!isChampionAction && (
                <div className="trash-icon-container">
                    <FaTrashAlt className="trash-icon" onClick={() => onRemove(index)} />
                </div>
            )}
        </li>
    );
};

const DroppableList = ({ championActions, buildActions, setBuildActions, onHover }) => {
    const moveAction = (fromIndex, toIndex) => {
        const actionsCopy = [...buildActions];
        const [movedItem] = actionsCopy.splice(fromIndex - championActions.length, 1);
        actionsCopy.splice(toIndex - championActions.length, 0, movedItem);
        setBuildActions(actionsCopy);
    };

    const handleRemoveAction = (index) => {
        const updatedActions = buildActions.filter((_, i) => i !== index - championActions.length);
        setBuildActions(updatedActions);
    };

    const maxBuildSize = 8;
    const actions = [...championActions, ...buildActions];
    const emptySlots = maxBuildSize - buildActions.length;

    return (
        <ul className="action-list">
            {actions.map((action, index) => (
                <DraggableAction
                    key={`action-${index}-${action?.name || 'empty'}`}
                    action={action}
                    index={index}
                    moveAction={moveAction}
                    onRemove={handleRemoveAction}
                    isChampionAction={index < championActions.length}
                    onHover={onHover}
                />
            ))}
            {emptySlots > 0 &&
                [...Array(emptySlots)].map((_, index) => (
                    <li className="action-item empty-slot" key={`empty-${index}`}>
                        <h4 className="action-name">Empty Slot</h4>
                    </li>
                ))}
        </ul>
    );
};


const BuildActionsList = ({ championActions, buildActions, setBuildActions, setHoveredAction }) => {
    return (
        <DndProvider backend={HTML5Backend}>
            <div className="build-actions-list">
                <DroppableList
                    championActions={championActions}
                    buildActions={buildActions}
                    setBuildActions={setBuildActions}
                    onHover={setHoveredAction}
                />
            </div>
        </DndProvider>
    );
};

export default BuildActionsList;

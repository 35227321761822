import React, { useState, useRef, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import io from 'socket.io-client';

import DraftTab from './draft-tab/draft-tab';
import BuildTab from './build-tab/build-tab';
import { BACKEND_URL } from '../constants';
import solbrewLogo from '../assets/SOLBREW.png';

import './draft.css';

const DraftPage = ({ draftId }) => {
    const [activeTab, setActiveTab] = useState('Draft');
    const [draftState, setDraftState] = useState(null);
    const [auth, setAuth] = useState({ auth: { token: '' } });
    const [cookieSet, setCookieSet] = useState(false);
    const socket = useRef(null);
    const { user, getAccessTokenSilently } = useAuth0();
    const username = user ? user['solbrew.io/username'] : null;

    const setCookie = async (gameId) => {
      try {
        const response = await fetch(`${BACKEND_URL}/draft/set_cookie?draft_id=${gameId}`, { 
            method: 'GET',
            credentials: 'include', // Important for sending cookies
            headers: {
                'Content-Type': 'application/json',
            }
        });
    
        if (response.ok) {
          console.log('Cookie set successfully');
          setCookieSet(true);
        } else {
          console.error('Failed to set cookie:', response.statusText);
        }
      } catch (error) {
        console.error('Error setting cookie:', error);
      }
    };

    const resetVariables = () => {};

    useEffect(() => {
        const fetchToken = async () => {
          try {
            const token = await getAccessTokenSilently();
            setAuth({ auth: { token } });
          } catch (error) {
            console.error('Error fetching the token', error);
          }
        };
        fetchToken();
        setCookie(draftId);
    }, []);

    useEffect(() => {
        if (!auth.auth.token) { return; }
        if (!cookieSet) { return; }
        socket.current = io(`${BACKEND_URL}/draft`, {...auth, withCredentials: true, transports: ['websocket']});
    
        socket.current.on('connect', () => {
            socket.current.emit('join', { draft_id: draftId });
            console.log('Draft WebSocket connection opened');
        });
    
        socket.current.on('draft_state', (data) => {
            console.log('WebSocket message received:', data);
            if (data.state) {
                setDraftState(data);
                resetVariables();
            }
        });
    
        socket.current.on('error', (error) => {
          console.error('WebSocket error:', error);
          alert(error.error);
        });
    
        return () => {
          socket.current.disconnect();
        };
    }, [draftId, auth, cookieSet]);

    if (!auth.auth.token) {
        return <div>Need to Login...</div>;
    }

    if (!draftState) {
        return (
            <div className='waiting-screen' style={{'backgroundImage': `url(${solbrewLogo})`}}>
              <p>Waiting for the other player to join!</p>
            </div>
        );
    }

    let { draft_id, phase, builds, current_cards, current_player, game_id } = draftState.state;
    let { champion_choices, selected_champion, player_id, ready } = builds[username];

    const submitReady = () => {
        socket.current.emit('ready', { draft_id: draftId, is_ready: ready ? 'False': 'True' });
    };

    console.log('draftState', draftState);
    console.log('ready', ready, typeof ready);
  
    const renderTabContent = () => {
      return (
        <div>
          <h1>Draft</h1>
          <div className={`${activeTab == 'Draft' ? 'selected-tab' : 'not-selected-tab'}`}>
            <DraftTab draftState={draftState} socket={socket} username={username} />
          </div>
          <div className={`${activeTab == 'Build' ? 'selected-tab' : 'not-selected-tab'}`}>
            <BuildTab draftState={draftState} socket={socket} username={username} />
          </div>
        </div>
      )
    };
  

    console.log('here');
    return (
      <div className='draft-page'>
        <div className="draft-tab-buttons">
          <button className={`${activeTab == 'Draft' ? 'selected-button' : 'not-selected-button'} ${(player_id == current_player) && phase == 'Action Draft' ? 'your-pick':'not-your-pick'}`} onClick={() => setActiveTab('Draft')}>Draft</button>
          <button className={`${activeTab == 'Build' ? 'selected-button' : 'not-selected-button'}`} onClick={() => setActiveTab('Build')}>Build</button>
        </div>
        <div className="draft-tab-content">
          {renderTabContent()}
        </div>
        {phase == 'Draft Done' && <button className={`ready-button`} onClick={submitReady}>{!ready ? 'Ready' : 'Unready'}</button>}
      </div>
    );
  };

export default DraftPage;